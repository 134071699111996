/* src/components/WelcomePage.css */

.welcome-container {
    text-align: center;
    padding: 2rem;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: 2rem auto;
  }
  
  .welcome-container h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }
  
  .welcome-container p {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
  
  .start-link {
    display: inline-block;
    padding: 0.75rem 1.5rem;
    background-color: #007bff;
    color: #fff;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.3s;
  }
  
  .start-link:hover {
    background-color: #0056b3;
  }
  