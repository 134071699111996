/* src/components/AuthPage.css */

.auth-container {
    text-align: center;
    padding: 2rem;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    margin: 2rem auto;
  }
  
  .auth-container h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  .auth-container form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .auth-container input {
    padding: 0.75rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .auth-container button {
    padding: 0.75rem;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .auth-container button:hover {
    background-color: #0056b3;
  }
  
  .auth-container p {
    margin-top: 1rem;
  }
  
  .auth-links {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-bottom: 1rem;
  }
  
  .auth-links a {
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
  }
  
  .auth-links a:hover {
    text-decoration: underline;
  }

  .social-login {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;
  }
  
  .social-login button {
    padding: 0.75rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    color: #fff;
  }
  
  .social-login button:hover {
    opacity: 0.8;
  }
  
  .social-login button:nth-child(1) {
    background-color: #db4437; /* Google */
  }
  
  .social-login button:nth-child(2) {
    background-color: #4267b2; /* Facebook */
  }
  