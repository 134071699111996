/* Basic Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
  background-color: #f9f9f9;
  color: #333;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.navbar {
  background-color: #007bff;
  padding: 1rem;
}

.nav-links {
  display: flex;
  justify-content: space-around;
}

.nav-links a {
  color: white;
  text-decoration: none;
  font-weight: bold;
}

.nav-links a:hover {
  text-decoration: underline;
}

/* Responsive Design */
@media (max-width: 768px) {
  .nav-links {
    flex-direction: column;
    align-items: center;
  }

  .nav-links a {
    margin-bottom: 1rem;
  }
}

.auth-container,
.welcome-container {
  text-align: center;
  padding: 2rem;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  margin: 2rem auto;
}

.auth-container h1,
.welcome-container h1 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.auth-container form,
.welcome-container form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.auth-container input,
.welcome-container input {
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.auth-container button,
.welcome-container button {
  padding: 0.75rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.auth-container button:hover,
.welcome-container button:hover {
  background-color: #0056b3;
}

.auth-container p,
.welcome-container p {
  margin-top: 1rem;
}

.error {
  color: red;
  margin-top: 1rem;
}

.social-login {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
}

.social-login button {
  padding: 0.75rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: #fff;
}

.social-login button:hover {
  opacity: 0.8;
}

.social-login button:nth-child(1) {
  background-color: #db4437; /* Google */
}

.social-login button:nth-child(2) {
  background-color: #4267b2; /* Facebook */
}

.create-tier-list-page {
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto;
}

.create-tier-list-page h1 {
  text-align: center;
  margin-bottom: 1rem;
}

.create-tier-list-page form {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.create-tier-list-page input {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.create-tier-list-page button {
  padding: 0.5rem 1rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.create-tier-list-page button:hover {
  background-color: #0056b3;
}

.tiers {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.tier {
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  min-height: 100px;
}

.tier h3 {
  margin-bottom: 1rem;
}

.tier-item {
  padding: 0.5rem;
  border: 1px solid #007bff;
  border-radius: 4px;
  background-color: #e7f1ff;
  margin-bottom: 0.5rem;
  cursor: pointer;
  text-align: center;
}

.unassigned-items {
  margin-top: 1rem;
}

.unassigned-items h3 {
  margin-bottom: 1rem;
}

.unassigned-items .tier-item {
  margin-bottom: 0.5rem;
}